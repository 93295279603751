import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AgregadoresService } from '../../shared/services/agregadores.service';
import { DialogSelectProdutoService } from '../../shared/dialog-select-produto/dialog-select-produto.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProdutoValidator } from '../../../validators/produtoValidator/produtoValidator.validator';

@Component({
  selector: 'app-criar-agregador',
  templateUrl: './criar-agregador.component.html',
  styleUrls: ['./criar-agregador.component.scss'],
  providers: [ProdutoValidator]
})
export class CriarAgregadorComponent implements OnInit, OnDestroy {

  private get empresa(): any {
    if (localStorage.getItem('empresa')) {
      return JSON.parse(localStorage.getItem('empresa'));
    }
  }
  public nomeProduto = '';

  public agregadorForm = new FormGroup({
    codigo_produto: new FormControl('', [
      Validators.required,
      this.verificaProduto.bind(this)
    ]),
    qtd_por_caixa: new FormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.pattern('^[0-9]*$')
    ]),
    qtd_produto: new FormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.pattern('^[0-9]*$')
    ])
  });

  public havePrint = true;

  constructor(
    public dialogRef: MatDialogRef<CriarAgregadorComponent>,
    private agregadoresService: AgregadoresService,
    private dialogSelectProduto: DialogSelectProdutoService,
    private _snackBar: MatSnackBar,
    private produtoValidator: ProdutoValidator
  ) { }

  ngOnDestroy() {
    this.dialogSelectProduto.setNomeProduto = null;
  }

  ngOnInit() {
    this.dialogSelectProduto.getNomeProduto.subscribe(observer => {
      if (observer && typeof observer === 'object') {
        if (observer.descricao_produto) {
          this.nomeProduto = observer.descricao_produto;
        } else {
          this.nomeProduto = '';
        }
      } else {
        this.nomeProduto = '';
      }
    });
  }

  public buscarProduto(): void {
    this.dialogSelectProduto.openDialog().subscribe(res => {
      if (res) {
        this.agregadorForm.controls.codigo_produto.setValue(res.codigo_produto);
        this.nomeProduto = res.descricao_produto;
      }
    });
  }

  public salvar(): void {
    if (this.agregadorForm.valid) {
      const empresa = this.empresa;
      const agregador: any = {
        codigo_produto : this.agregadorForm.controls.codigo_produto.value,
        qtd_por_caixa : this.agregadorForm.controls.qtd_por_caixa.value,
        qtd_produto : this.agregadorForm.controls.qtd_produto.value,
        empresa_id: empresa.empresa_id
      };
      this.agregadoresService.criarAgregadores(agregador).then(res => {
        if (res.status === 200 && res.data.success === true) {
          this._snackBar.open(res.data.message, '', {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom'
          });
          const data = {
            print : this.havePrint,
            etiquetas : res.data.data
          };
          this.dialogRef.close(data);
        } else {
          this._snackBar.open(res.data.message, '', {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom'
          });
        }
      }).catch(err => {
        this._snackBar.open(err.data.message, '', {
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'bottom'
        });
      });
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public getErrorMessageCodigoBarras() {
    if (this.agregadorForm.controls.codigo_produto.hasError('required')) {
      return 'Campo Obrigatório';
    }
    if (this.agregadorForm.controls.codigo_produto.hasError('produtoValid')) {
      return 'Produto inválido';
    }
  }

  public getErrorMessageQtdCaixa() {
    if (this.agregadorForm.controls.qtd_por_caixa.hasError('required')) {
      return 'Campo Obrigatório';
    } else if (this.agregadorForm.controls.qtd_por_caixa.hasError('min')) {
      return 'Deve haver ao menos 1 produto por caixa';
    }
    if (this.agregadorForm.controls.qtd_por_caixa.hasError('pattern')) {
      return 'Apenas números inteiros';
    }
  }

  public getErrorMessageQtd() {
    if (this.agregadorForm.controls.qtd_produto.hasError('required')) {
      return 'Campo Obrigatório';
    } else if (this.agregadorForm.controls.qtd_produto.hasError('min')) {
      return 'Deve haver ao menos 1 caixa';
    }
    if (this.agregadorForm.controls.qtd_produto.hasError('pattern')) {
      return 'Apenas números inteiros';
    }
  }

  public verificaProduto(control) {
    return this.produtoValidator.verificaProduto(control);
  }
  inputChange() {
    if (this.agregadorForm.controls.codigo_produto.value.trim() === '') {
      this.dialogSelectProduto.setNomeProduto = null;
    }
  }

}
