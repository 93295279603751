import { Component, OnInit, Input } from '@angular/core';
import { CHAVE_CENSO } from '../../../constants/chaves-sistemas';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  /**
   * Referencia para a sidenav do Angular Material
   *
   * @type {*}
   * @memberof ToolbarComponent
   */
  @Input() sidenav: any;

  schools: Array<any>;
  schoolSelected: any;

  constructor() { }

  ngOnInit() {
    const schools = JSON.parse(localStorage.getItem('escolas'));
    schools.forEach((school) => {
      school['disabled'] = false;
      const schoolWithPermission = school.sistemas.find((system) => {
        return system.chave === CHAVE_CENSO;
      });
      if (!schoolWithPermission) {
        school['disabled'] = true;
      }
    });

    this.schools = schools;
    // indexEscola é um indice legado, nas próximas versões deve-se remover esse indice
    const profileIndex = localStorage.getItem('perfil_ativo') || localStorage.getItem('indexEscola');
    if (this.schools) {
      this.schoolSelected = this.schools[Number(profileIndex)];
    }
  }

  changeEscola(escola: any, index: any) {
    this.schoolSelected = this.schools[index];
    localStorage.setItem('inst_codigo', escola.codigo);
    localStorage.setItem('perfil_ativo', index);
    location.reload();
  }
}
