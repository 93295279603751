import { HomeLayoutComponent } from './views/home/layout/home-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { MainContainerWrapperComponent } from './views/shared/main-container-wrapper/main-container-wrapper.component';
import { IsAuthenticatedGuard } from './guards/auth/isauth.guard';
import { GerenciadorAgregadoresModule } from './views/logistica/gerenciador-agregadores/gerenciador-agregadores.module';
import { PERMISSOES } from './constants/permissoes';

const routes: Routes = [
  {
    path: 'app',
    component: MainContainerWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: './views/home/home.module#HomeModule',
        canActivate: [IsAuthenticatedGuard],
        data: {
          permissions: [
            PERMISSOES.LISTAGEM_AGREGADORES
          ]
        }
      },
      {
        path: 'gerenciador-agregadores',
        loadChildren: './views/logistica/gerenciador-agregadores/gerenciador-agregadores.module#GerenciadorAgregadoresModule',
        canActivate: [IsAuthenticatedGuard],
        data: {
          permissions: [
            PERMISSOES.CRIAR_AGREGADOR
          ]
        }
      },
      {
        path: 'gerenciamento',
        loadChildren: () => import('./views/gerenciamento/gerenciamento.module').then(m => m.GerenciamentoModule),
        canActivate: [IsAuthenticatedGuard]
      }
    ],
  },
  {
    path: 'auth',
    component: AppComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'app',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
