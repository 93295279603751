import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, SimpleChanges, ChangeDetectorRef} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgregadoresService } from '../services/agregadores.service';
import { DesativarDialogComponent } from './desativar-dialog/desativar-dialog.component';
import { InformacoesAdicionaisComponent } from './informacoes-adicionais/informacoes-adicionais.component';

export interface IListaAgregadores {
  id: number;
  codigo_barras: number;
  codigo_produto: number;
  descricao_produto: string;
  qtd: number;
  desativado: boolean;
  tools?: any;
}

@Component({
  selector: 'tabela-agregadores',
  templateUrl: './tabela-agregadores.component.html',
  styleUrls: ['./tabela-agregadores.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabelaAgregadoresComponent implements OnInit, OnChanges {

  public listaAgregadores: IListaAgregadores[] = [];
  @Input() agregadores: any;
  @Input() mostrarDesativados: boolean;
  @Output() scrollTable = new EventEmitter();
  @Output() sendToPrint = new EventEmitter();
  @Output() recarregarLista = new EventEmitter();

  private get empresa(): any {
    if (localStorage.getItem('empresa')) {
      return JSON.parse(localStorage.getItem('empresa'));
    }
  }

  public displayedColumns: string[] = ['agregador_id', 'codigo_barras', 'codigo_produto', 'descricao_produto', 'qtd_produtos', 'tools'];
  public dataSource;

  constructor(
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private agregadoresService: AgregadoresService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {  }

  ngOnChanges(changes: SimpleChanges) {
    const agregadores_change = changes.agregadores;
    if (agregadores_change) {
      if (
        (agregadores_change.firstChange === true) ||
        (agregadores_change.currentValue.length > agregadores_change.previousValue.length)
       ) {
        const data: any = agregadores_change.currentValue;
        data.map((element: any) => {
          if (Object.keys(element.desativado_por).length > 0) {
            element.desativado = true;
          }
          return element;
        });
        this.dataSource = data;
        this.ref.detectChanges();
      } else if (
        (agregadores_change.firstChange === false) ||
        (JSON.stringify(agregadores_change.currentValue) !== JSON.stringify(agregadores_change.previousValue))
      ) {
        const data: any = agregadores_change.currentValue;
        data.map((element: any) => {
          if (Object.keys(element.desativado_por).length > 0) {
            element.desativado = true;
          }
          return element;
        });
        this.dataSource = data;
        this.ref.detectChanges();
      }
    }
  }

  public imprimir(element: any): any {
    const copia_element = element;
    copia_element.empresa_cnpj = this.empresa.empresa_cnpj;
    copia_element.empresa_nome = this.empresa.empresa_nome;
    const arr = [];
    arr.push(copia_element);
    this.sendToPrint.emit(arr);
  }

  public desativar(id: number, index: number): any {
    const dialogRef = this.dialog.open(DesativarDialogComponent, {
      data: {
        agregador_id: id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.agregadoresService.desativarAgregador({
          agregador_id : id
        }).then(res => {
          if (res.data.success) {
            this.dataSource[index].desativado = true;
            this._snackBar.open(res.data.message, '', {
              duration: 5000,
              horizontalPosition: 'right',
              verticalPosition: 'bottom'
            });
            this.ref.detectChanges();
            this.recarregarLista.emit(true);
          } else {
            this._snackBar.open(res.data.message, '', {
              duration: 5000,
              horizontalPosition: 'right',
              verticalPosition: 'bottom'
            });
          }
        }).catch(err => {
          this._snackBar.open(err.data.message, '', {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom'
          });
        });
      }
    });
  }

  public informacoes(element: any): any {
    this.dialog.open(InformacoesAdicionaisComponent, {
      data: {
        criado_por : element.criado_por,
        desativado_por : element.desativado_por
      }
    });
  }

  public onTableScroll(e): void {
    this.ref.detectChanges();
    const tableHeight = document.getElementsByTagName('table')[0].offsetHeight;
    const altura = e.srcElement.offsetHeight;
    const scroll = e.srcElement.scrollTop;
    const scrollBottom = tableHeight - altura - scroll;

    if (scrollBottom === 0) {
      this.scrollTable.emit(true);
    }
  }
}
