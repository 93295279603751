import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BeepService } from '../../../services/beep/beep.service';
import { PrintBoardService } from '../../shared/print-board/print-board.service';
import { AgregadoresService } from '../../shared/services/agregadores.service';
import { EmpresasService } from '../../shared/services/empresas.service';
import { CriarAgregadorComponent } from '../criar-agregador/criar-agregador.component';

@Component({
  selector: 'app-gerenciador-agregadores',
  templateUrl: './gerenciador-agregadores.component.html',
  styleUrls: ['./gerenciador-agregadores.component.scss']
})
export class GerenciadorAgregadoresComponent implements OnInit {

  public listaEmpresas: any = [];

  public filtro: any = {
    empresa_id : -1,
    codigo_barra: null,
    start: 0,
    limit: 30
  };
  private table = false;
  private filtro_bkp: any;
  private end = 0;

  private paginaAtual = 1;
  private totalPaginas = null;

  public mostrarDesativados = true;

  public resultadoAgregadores: any = [];
  private first = true;

  constructor(
    private empresasService: EmpresasService,
    private agregadoresService: AgregadoresService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private beepService: BeepService,
    private printService: PrintBoardService
  ) {
    this.initialization();
  }

  ngOnInit() {
  }

  private initialization(): void {
    this.empresasService.listarEmpresas().then(response => {
      if (response.status === 200 && response.data.success === true) {
        this.listaEmpresas = response.data.data;
        const empresa: any = this.getEmpresa();
        if (empresa) {
          this.filtro.empresa_id = empresa.empresa_id;
          this.buscar();
        }
      }
    });
  }

  public buscar(update: boolean = false): void {
    if (this.filtro.empresa_id === -1 || this.filtro.empresa_id === null) {
      this._snackBar.open('O campo empresa é obrigatório', '', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom'
      });
    } else {
      const empresa = this.listaEmpresas.filter(item => {
        return item.empresa_id === this.filtro.empresa_id;
      });
      if (empresa.length > 0) {
        this.defineEmpresa(empresa[0]);
      }

      if (update) {
        let dados_filtro;
        if (!this.filtro.codigo_barras) {
          dados_filtro = this.filtro;
          dados_filtro.codigo_barras = this.filtro_bkp.codigo_barras ? (this.filtro_bkp.codigo_barras).toString() : null;
          this.filtro_bkp = dados_filtro;
        } else {
          dados_filtro = this.filtro;
          dados_filtro.codigo_barras = (dados_filtro.codigo_barras).toString();
        }

        this.agregadoresService.buscarAgregadores(dados_filtro).then(res => {
          if (res.data.success === true) {

            if (this.first) {
              this.first = false;
              this.resultadoAgregadores = [];
              const resposta = res.data.data;
              resposta.forEach(element => {
                this.resultadoAgregadores.push(element);
              });
            } else {
              const copia: any = Object.values(Object.assign({}, this.resultadoAgregadores));
              const resposta = res.data.data.agregadores;
              this.paginaAtual = res.data.data.pagina_atual;
              this.totalPaginas = res.data.data.total_paginas;
              resposta.forEach(element => {
                copia.push(element);
              });
              this.resultadoAgregadores = copia;
            }
            this.filtro.start = this.end;
            this.end = this.filtro.limit + this.filtro.start;
            if (this.filtro.codigo_barra) {
              if (res.data.data.agregadores.length > 0) {
                this.beepService.play('ok');
              } else {
                this.beepService.play('erro');
              }
            }
            this.filtro.codigo_barra = null;
            this.table = true;
          } else {
            this.resultadoAgregadores = [];
            this._snackBar.open(res.data.message, '', {
              duration: 5000,
              horizontalPosition: 'right',
              verticalPosition: 'bottom'
            });
          }
        }).catch(err => {
          if (err.status === 409) {
            this._snackBar.open('Não há mais agregadores para mostrar', '', {
              duration: 5000,
              horizontalPosition: 'right',
              verticalPosition: 'bottom'
            });
          } else {
            this._snackBar.open(err.data.message, '', {
              duration: 5000,
              horizontalPosition: 'right',
              verticalPosition: 'bottom'
            });
          }
          this.beepService.play('erro');
        });
      } else {
        this.first = true;
        this.filtro.start = 0;
        this.end = this.filtro.limit + this.filtro.start;

        let dados_filtro;
        dados_filtro = this.filtro;
        if (dados_filtro.codigo_barras) {
          dados_filtro.codigo_barras = (dados_filtro.codigo_barras).toString();
        }

        this.agregadoresService.buscarAgregadores(dados_filtro).then(res => {
          if (res.status === 200 && res.data.success === true) {
            this.resultadoAgregadores = [];
            const resposta = res.data.data;
            this.resultadoAgregadores = resposta.agregadores;
            this.paginaAtual = resposta.pagina_atual;
            this.totalPaginas = resposta.total_paginas;

            this.filtro.start = this.end;
            this.end = this.filtro.limit + this.filtro.start;
            this.filtro_bkp = this.filtro;
            if (this.filtro.codigo_barra) {
              if (resposta.agregadores.length > 0) {
                this.beepService.play('ok');
              } else {
                this.beepService.play('erro');
              }
            }
            this.first = false;
            this.filtro.codigo_barra = null;
            this.table = true;
          } else {
            this.resultadoAgregadores = [];
            this._snackBar.open(res.data.message, '', {
              duration: 5000,
              horizontalPosition: 'right',
              verticalPosition: 'bottom'
            });
            this.beepService.play('erro');
          }
        }).catch(err => {
          this.resultadoAgregadores = [];
          this.table = true;
          this._snackBar.open(err.data.message, '', {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom'
          });
          this.beepService.play('erro');
        });
      }
    }
  }

  public subirScroll() {
    const div = document.getElementsByClassName('table-container').item(0);
    if (div) {
      div.scrollTo(0, 0);
    }
  }

  public runInfiniteScroll($event) {
    if ($event) {
      if (this.totalPaginas > this.paginaAtual) {
        this.buscar(true);
      }
    }
  }

  public criarAgregador(): void {
    const dialogRef = this.dialog.open(CriarAgregadorComponent, {
      maxWidth: '400px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.print) {
          this.imprimir(result.etiquetas);
        }
      }
    });
  }

  public defineEmpresa(empresa): void {
    localStorage.setItem('empresa', JSON.stringify(empresa));
  }

  public getEmpresa(): any {
    if (localStorage.getItem('empresa')) {
      return JSON.parse(localStorage.getItem('empresa'));
    }
  }

  public imprimir(arr): void {
    if (arr.length > 0) {
      this.printService.setToPrint = arr;
      setTimeout(() => {
        window.print();
        window.onafterprint = () => {
          this.printService.setToPrint = [];
        };
      }, 500);
    }
  }

  public canCreate(): boolean {
    const empresa_id = this.filtro.empresa_id;
    if (empresa_id) {
      if (empresa_id !== -1 && empresa_id !== undefined && empresa_id !== null && empresa_id > 0) {
        return false;
      }
    }
    return true;
  }

  public changeEmpresa(event, input) {
    const empresa = this.listaEmpresas.filter(item => {
      return item.empresa_id === event.value;
    });
    if (empresa.length > 0) {
      this.defineEmpresa(empresa[0]);
      setTimeout (() => {
        input.focus();
      }, 500);
    }
  }

  public refresh(event) {
    if (event) {
      this.buscar();
    }
  }
}
