import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SidenavService } from '../services/sidenav.service';
import { environment as env } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { AppService } from '../../../services/app/app.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  @Output() itemEvent: EventEmitter<any>;

  sidenav: Array<any>;
  collapsed: boolean;
  hideItem: boolean;
  userAuthSubscription: Subscription;

  constructor(
    private sidenavService: SidenavService,
    private appService: AppService,
    private mediaObserver: MediaObserver
  ) {
    this.collapsed = false;
    this.hideItem = false;
    this.sidenav = [];
    this.itemEvent = new EventEmitter();
    this.sidenavService.collapsed.subscribe(
      (value) => {
        this.collapsed = value;
        this.hideItem = this.collapsed;
      }
    );
    this.mediaObserver.asObservable().subscribe(
      (change: MediaChange[]) => {
        if (change[0].mqAlias === 'xs' || change[0].mqAlias === 'sm') {
          this.collapsed = false;
          this.hideItem = false;
        }
      });
  }

  ngOnInit(): void {
    this.initMenu();
  }

  initMenu() {
    this.sidenav = [
      {
        icon: 'home',
        title: 'Início',
        svgIcon: false,
        homeLink: true,
        route: {
          url: `${env.pluriConnect.front}`,
          exact: false,
          external: true
        },
        permission: true,
        subitems: []
      },
      {
        icon: 'ballot',
        svgIcon: false,
        title: 'Logística',
        permission: true,
        subitems: [
          {
            title: 'Gerenciamento de Agregadores',
            route: {
              url: '/app/gerenciador-agregadores',
              exact: true,
              external: false
            },
            permission: true,
            subitems: []
          },
        ]
      },
      {
        icon: 'settings',
        svgIcon: false,
        title: 'Gerenciamento',
        permission: true,
        subitems: [
          {
            title: 'Gerenciamento de Usuários',
            route: {
              url: '/app/gerenciamento/usuarios',
              exact: true,
              external: false
            },
            permission: true,
            subitems: []
          },
        ]
      }
    ];
  }

  navegate(data: any) {
    const item = data.item;
    const subitem = data.subitem;

    if (subitem) {
      this.itemEvent.emit({ item, subitem });
      if (subitem.route.external || subitem.homeLink) {
        window.open(subitem.route.url, subitem.homeLink ? '_self' : '_blank');
        return;
      }
    } else {
      if (item.subitems.length > 0) {
        if (this.collapsed === true) {
          this.toggle();
        }
        this.sidenav.map((elem) => {
          if (elem.hasOwnProperty('isOpen') && elem.icon !== item.icon) {
            elem.isOpen = false;
          }
        });
        item.isOpen = !item.isOpen;
      } else {
        this.itemEvent.emit({ item, subitem });
        this.sidenav.map((elem) => {
          if (elem.hasOwnProperty('isOpen')) {
            elem.isOpen = false;
          }
        });
        if (item.route.external || item.homeLink) {
          window.open(item.route.url, item.homeLink ? '_self' : '_blank');
          return;
        }
      }
    }
  }

  toggle() {
    if (this.collapsed === true) {
      setTimeout(() => this.hideItem = false, 200);
    } else {
      this.hideItem = true;
    }
    this.collapsed = !this.collapsed;
    this.sidenavService.collapse(this.collapsed);
  }

  ngOnDestroy() {
    if (this.userAuthSubscription) {
      this.userAuthSubscription.unsubscribe();
    }
  }

}
