import { HomeLayoutComponent } from './views/home/layout/home-layout.component';
import { FullscreenLoaderService } from './views/shared/services/fullscreen-loader.service';
import { SharedModule } from './views/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth/auth.service';
import { AppService } from './services/app/app.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material';
import { GuardsModule } from './guards/guards.module';
import { GerenciadorAgregadoresModule } from '../app/views/logistica/gerenciador-agregadores/gerenciador-agregadores.module';


@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    GuardsModule,
    HttpClientModule,
    SharedModule,
    GerenciadorAgregadoresModule,
  ],
  providers: [AppService, AuthService, FullscreenLoaderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
