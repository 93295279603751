import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {

  constructor() { }

  getUser(token: String) {
    const params = {
      rotina_curta: 1,
    };

    const headers = {
      Authorization: token,
    };

    const instance = axios.create({
      baseURL : environment.pluriConnect.back
    });

    return instance.get(`usuarios`, { params: params, headers: headers });
  }

  logout() {
    localStorage.clear();
    window.open(`${environment.pluriConnect.front}/logout`, '_self');
  }

  registerStorage(user: any, schools: any, profileIndex: any) {
    localStorage.setItem('escolas', JSON.stringify(schools));
    localStorage.setItem('usuario', JSON.stringify(user));
    localStorage.setItem('perfil_ativo', profileIndex);
  }

}
