import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { AppService } from '../../services/app/app.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private appService: AppService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Promise<boolean> | boolean {
    try {
      const token = route.queryParams.t || route.queryParams.token;
      const instCode = route.queryParams.ic || route.queryParams.inst_codigo;
      const profileIndex = route.queryParams.pi || route.queryParams.perfil_index || 0;

      if (token && instCode) {
        localStorage.clear();
        localStorage.setItem('inst_codigo', instCode);
        localStorage.setItem('token', token);

        return this.authService.getUser(token)
          .then((res) => {
            const data = res.data.data;
            this.authService.registerStorage(data.usuario, data.escolas, profileIndex);
            this.appService.redirectToHome();
            return true;
          }).catch(() => {
            localStorage.clear();
            return false;
          });
      } else {
        this.authService.logout();
        return false;
      }
    } catch (err) {
      this.authService.logout();
      return false;
    }
  }

}
